export const messages = {
    en: {
        intro: "A service made for renewing Facebook Marketplace ads with ease",
        intro2: "* At this time we support only 'Item' listings",
        header1: "Renew your Marketplace ads with a few clicks",
        header2: "using our service",
        choose_plan: "Join in a click",
        plan_instructions: "After purchase you will receive an email with instructions on how to activate the service",
        month: "Month",
        plan_title_0: "First Month",
        plan_title_1: "Free",
        and: "and then",
        plan_price: "10 ILS / month",
        pro_li_1: "Unlimited ad renewal",
        pro_li_2: "Manage 2 users",
        pro_li_3: "1 computer license",
        pro_li_4: "Email and Instagram Support",
        aboutUsHeader: "About Us",
        aboutUsContent: "We provide a user friendly software to help you renew your Facebook Marketplace Ads. Our goal is to help your ads stay relevant, and let you manage them with ease. For any inquiries, please contact us at",
    },
    he: {
        intro: "שירות עבור חידוש מודעות בפייסבוק מרקטפלייס בקלות",
        intro2: "'נכון לעכשיו אנו תומכים רק במודעות מסוג 'פריט *",
        header1: "חדשו את מודעות המרקטפלייס שלכם במספר קליקים בודדים",
        header2: "באמצעות השירות שלנו",
        choose_plan: "מצטרפים בקליק",
        plan_instructions: "בסיום הרכישה ישלח מייל אוטומטי עם הוראות להפעלת השירות",
        month: "חודש",
        plan_title_0: "חודש ראשון",
        plan_title_1: "חינם",
        and: "ואז",
        plan_price:"10₪ / חודש",
        pro_li_1: "חידוש ללא מגבלת מודעות",
        pro_li_2: "ניהול 2 משתמשים במקביל",
        pro_li_3: "תמיכה במייל ואנסטגרם",
        pro_li_4: "רישיון למחשב אחד",
        aboutUsHeader: "עלינו",
        aboutUsContent: "אנו מספקים תוכנה ידידותית שתעזור לכם לחדש את המודעות שלכם בפייסבוק מרקטפלייס. המטרה שלנו היא לעזור למודעות שלכם להישאר רלוונטיות ולאפשר לכם לנהל אותן בקלות. לכל שאלה, צרו קשר בעמוד האנסטגרם שלנו",
    },
  };
  